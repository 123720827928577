import React from 'react'
import { graphql } from 'gatsby'
import Navbar from '../../../sections/Navbar/Navbar'
import Footer from '../../../sections/Footer'
import { PrismicRichText } from "@prismicio/react";
import "../../../styles/AdminPage.scss";

const FAQ = ({ data }: any) => {
  const info = data.prismicFagqsMsTeams.dataRaw
  const infoImg = data.prismicFagqsMsTeams.data
  return (
    <div>
      <Navbar />
      <div className='AdminPage'>
        <div className='AdminPage-title'>
          <PrismicRichText field={info.title} />
        </div>
        <div className='AdminPage-second-title'>
          <PrismicRichText field={info.updated} />
        </div>
        <div className='AdminPage-content'>
          <PrismicRichText field={info.texte} />
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default FAQ;

export const query = graphql`
query FAQ {
  prismicFagqsMsTeams(lang: {eq: "en-us"}) {
    dataRaw
    data {
      logo {
        url
        alt
      }
    }
  }
}
`
